import { DefaultLayout } from '@strike-apps/commerce-dashboard/feature-layout'
import { SEO } from '@strike-apps/commerce-dashboard/ui'
import { Button } from '@strike-apps/shared/ui'
import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { getServerSession } from 'next-auth'
import { signIn } from 'next-auth/react'
import { nextAuthOptions } from '../../lib/auth'
import type { DefaultPageProps } from '../../lib/page'

export function Login() {
  return (
    <DefaultLayout>
      <SEO />
      <Button onClick={() => signIn('strike')} colorScheme="secondary">
        Login with Strike
      </Button>
    </DefaultLayout>
  )
}

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<DefaultPageProps>> {
  const session = await getServerSession(context.req, context.res, nextAuthOptions)

  if (session !== null) {
    return {
      redirect: {
        destination: '/',
        permanent: true,
      },
    }
  }

  return {
    props: {
      session,
    },
  }
}

export default Login
